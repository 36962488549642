/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines-per-function */
/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { InfoOutlined } from '@mui/icons-material';
import {
  Autocomplete,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
  MenuItem,
  Divider
} from '@mui/material';
import MapContext from 'contexts/MapContext';
import { getFeature, updateLocation } from '@services/buildingService';
import PageHeader from '@components/PageHeader';
import { LocationType, LocationTypeEn, LocationTypeOptions } from '@constants';
import { convertType } from '@helpers/formatLocations';
import { buildingAddressesAtom, isLocationsLoadingAtom, locationsAtom } from '@store';
import ButtonWithLoading from '@components/ui-kit/ButtonWithLoading';
import useAdvancedNavigate from '@hooks/use-advanced-navigate';
import CircularLoading from '@components/ui-kit/CircularLoading';
import { addLocationMarkers, clearAllMarkers } from '@utils/map';
import { FeatureBuilding, IFeatureData } from '@types';
import useSnackbar from '@hooks/use-snackbar';
import CypressIds from '../../../../cypressIds';
import FeatureAttachedBuildings from './FeatureAttachedBuildings';

const EditFeature = () => {
  const { locationId } = useParams();
  const [error, setError] = useState<{
    value: string;
    fieldName: string;
  } | null>(null);
  const { map } = useContext(MapContext);
  const [type, setType] = useState<LocationTypeEn | null>(null);
  const [name, setName] = useState<string>('');
  const [locations] = useAtom(locationsAtom);
  const [isLocationsLoading] = useAtom(isLocationsLoadingAtom);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const [coordinates, setCoordinates] = useState([0, 0]);
  const currentLocationId = useRef<string | null>(null);
  const [marker, setMarker] = useState<maplibregl.Marker | null>(null);
  const [, setBuildingAddresses] = useAtom(buildingAddressesAtom);
  const navigate = useAdvancedNavigate();
  const [isLocationUpdating, setIsLocationUpdating] = useState(false);
  const [buildingsAttachedToFeature, setBuildingsAttachedToFeature] = useState<
    FeatureBuilding[] | null
  >(null);

  const snackbar = useSnackbar();

  useEffect(() => {
    if (!locationId) return;
    if (!buildingsAttachedToFeature) {
      setIsLocationLoading(true);
      getFeature(locationId)
        .then((data) => {
          const { buildings } = data;
          const locationType = convertType(data.type as LocationType) as LocationTypeEn;
          const [lng, lat] = data.point.coordinates as number[];
          setName(data.title as string);
          setCoordinates([+lng, +lat]);
          setType(locationType);
          const isBuildingsOrderChanged = buildings?.some((building) => building.order !== 0);
          const formattedBuildings = isBuildingsOrderChanged
            ? buildings.sort((previous, next) => {
                if (previous.order < next.order) return -1;
                if (previous.order > next.order) return 1;
                if (previous.id < next.id) return -1;
                if (previous.id > next.id) return 1;

                return 0;
              })
            : buildings;

          const formattedData = formattedBuildings.map((item) => {
            const isAddressesOrderChanged = item.addresses.some((address) => address.order !== 0);
            const formattedAddresses = isAddressesOrderChanged
              ? item.addresses.sort((prev, next) => {
                  if (prev.order < next.order) return -1;
                  if (prev.order > next.order) return 1;
                  if (prev.id < next.id) return -1;
                  if (prev.id > next.id) return 1;
                  return 0;
                })
              : item.addresses;
            return {
              ...item,
              addresses: formattedAddresses
            };
          });
          setBuildingsAttachedToFeature(formattedData);
        })
        .finally(() => {
          setIsLocationLoading(false);
        });
    }
  }, [buildingsAttachedToFeature, locationId, setBuildingsAttachedToFeature]);

  useEffect(() => {
    if ((currentLocationId?.current === locationId && marker) || !map || !locations) return;

    if (!locationId) return navigate('/');
    currentLocationId.current = locationId;

    clearAllMarkers(map);
    const currentFeatures = locations.filter((location) => location.id == locationId);
    const glMarker = addLocationMarkers(currentFeatures, map, +locationId);
    const coordsElement = document.getElementById('coordinates') as HTMLElement;
    if (Array.isArray(glMarker)) {
      return;
    }
    glMarker?.on('drag', (e) => {
      coordsElement.style.display = 'block';
      const { x, y } = e.target._pos;
      const { lng, lat } = e.target._lngLat;
      coordsElement.style.top = `${y - 15}px`;
      coordsElement.style.left = `${x + 230}px`;
      coordsElement.innerHTML = `${lng.toFixed(7)}, ${lat.toFixed(7)}`;
      setCoordinates([parseFloat(lng.toFixed(7)), parseFloat(lat.toFixed(7))]);
    });

    glMarker?.on('dragend', () => {
      coordsElement.style.display = 'none';
      const { lng, lat } = glMarker.getLngLat();
      setCoordinates([parseFloat(lng.toFixed(7)), parseFloat(lat.toFixed(7))]);
    });
    setMarker(glMarker);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, isLocationsLoading, locationId, locations, map, marker]);

  useEffect(
    () => () => {
      marker?.remove();
      (document.getElementById('coordinates') as HTMLElement).style.display = 'none';
    },
    [marker]
  );

  const handleBack = () => {
    navigate(`/`);
  };

  // eslint-disable-next-line consistent-return
  const handleSave = () => {
    if (!name) {
      return setError({
        value: 'Naam is verplicht!',
        fieldName: 'name'
      });
    }
    if (!type) {
      return setError({
        value: 'Type is verplicht!',
        fieldName: 'type'
      });
    }
    if (!coordinates || coordinates.length !== 2 || coordinates[0] === 0 || coordinates[1] === 0) {
      return setError({
        value: 'Coördinatenveld is verplicht!',
        fieldName: 'coordinates'
      });
    }
    setIsLocationUpdating(true);
    const payload: IFeatureData = {
      title: name,
      type: convertType(type as LocationTypeEn),
      point: {
        type: 'Point',
        coordinates: coordinates as [number, number],
        crs: {
          type: 'name',
          properties: {
            name: 'EPSG:4326'
          }
        }
      },
      buildings:
        buildingsAttachedToFeature?.map((building, index) => ({
          ...building,
          order: index,
          addresses: building.addresses.map((address, addressIndex) => ({
            ...address,
            order: addressIndex
          }))
        })) || []
    };
    updateLocation(locationId as string, payload)
      .then(() => {
        snackbar.show(`Locatie is succesvol bijgewerkt`);
        setBuildingAddresses(null);
        handleBack();
      })
      .finally(() => {
        setIsLocationUpdating(false);
      });
  };

  const isLoading = (isLocationsLoading && !locations) || isLocationLoading;

  return (
    <>
      <Grid>
        <PageHeader onClick={handleBack} label='Locatie bewerken' />
      </Grid>
      <Grid
        p={3}
        display='flex'
        flexDirection='column'
        gap={0.5}
        height='100%'
        sx={{
          overflowY: 'auto'
        }}
      >
        {isLoading ? (
          <CircularLoading size={32} />
        ) : (
          <Grid container gap={4} flexDirection='column'>
            <Grid>
              <FormControl fullWidth>
                <TextField
                  label='Naam'
                  {...(error?.fieldName === 'name' && {
                    error: true,
                    helperText: error?.value
                  })}
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  inputProps={{
                    'data-cy': CypressIds.LOCATION_NAME_INPUT
                  }}
                />
              </FormControl>
            </Grid>
            <Grid>
              <FormControl fullWidth>
                <Autocomplete
                  value={type}
                  onChange={(e, value) => setType(value)}
                  options={LocationTypeOptions.map((item) => item.id)}
                  renderOption={(props, option) => {
                    return <MenuItem {...props}>{option}</MenuItem>;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label='Type'
                      data-cy={CypressIds.LOCATION_TYPE_SELECT}
                      {...(error?.fieldName === 'type' && {
                        error: true,
                        helperText: error?.value
                      })}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid>
              <Grid
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <TextField
                  onChange={(e) => setCoordinates(e.target.value.split(',').map(Number))}
                  value={`${coordinates[0]}, ${coordinates[1]}`}
                  sx={{
                    '& fieldset': {
                      border: '1px solid #e2e8f0',
                      borderRadius: 0,
                      borderTopLeftRadius: 4,
                      borderBottomLeftRadius: 4
                    },
                    pointerEvents: 'none'
                  }}
                  label='Coördinaten'
                  {...(error?.fieldName === 'coordinates' &&
                    !marker && {
                      error: true,
                      helperText: error?.value
                    })}
                />
              </Grid>
              {marker && (
                <Grid
                  sx={{
                    mt: 3,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1,
                    color: 'text.secondary'
                  }}
                >
                  <InfoOutlined />
                  <Typography variant='caption'>
                    U kunt de markering slepen om de locatie te wijzigen
                  </Typography>
                </Grid>
              )}
            </Grid>
            <FeatureAttachedBuildings
              buildingsAttachedToFeature={buildingsAttachedToFeature}
              setBuildingsAttachedToFeature={setBuildingsAttachedToFeature}
            />
          </Grid>
        )}
      </Grid>
      <Divider flexItem />
      <Grid display='flex' justifyContent='flex-end' p={3} gap={1}>
        <Button
          type='button'
          onClick={handleBack}
          variant='text'
          sx={{
            fontSize: '0.9375rem',
            color: (theme) => theme.palette.neutral.light,
            fontWeight: 500
          }}
        >
          Annuleren
        </Button>
        <ButtonWithLoading
          loading={isLocationUpdating || isLoading}
          type='button'
          onClick={handleSave}
          variant='contained'
          data-cy={CypressIds.COMMON_CONFIRM_BUTTON}
        >
          Opslaan
        </ButtonWithLoading>
      </Grid>
    </>
  );
};

export default EditFeature;
